import styled from 'styled-components';

import FONTS from 'css/fonts';
import BREAKPOINTS from 'css/breakpoints';
import COLORS from 'css/colors';
import { StackedProps } from 'components/types';
import P from 'components/P';

export const Wrapper = styled.div<StackedProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    !props.stacked &&
    `
    margin-bottom: 60px;

    @media (min-width: ${BREAKPOINTS.md.min}px) {
      margin-bottom: 100px;
    }

    @media (min-width: ${BREAKPOINTS.lg.min}px) {
      margin-bottom: 125px;
    }
  `}
`;

export const OfferElement = styled.div<StackedProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  padding: 12px 0 12px 0;
  text-align: center;

  ${(props) =>
    props.stacked &&
    `
    flex-direction: column;
    border-bottom: 1px solid ${COLORS.lightGray};
    margin-bottom: 40px;
    padding: 0 0 40px;
  `}

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    ${(props) =>
      !props.stacked &&
      `
      flex-basis: 42%;
      justify-content: flex-start;
      padding: 12px 0 12px 45px;
    `}
  }
`;

type TextProps = {
  uppercase?: boolean;
  bold?: boolean;
};

export const Headline = styled.p<TextProps>`
  font-family: ${FONTS.primary};
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const StyledP = styled(P)`
  @media (min-width: ${BREAKPOINTS.md.min}px) {
    width: 75%;
  }
`;

export const ImageWrapper = styled.div<StackedProps>`
  svg {
    height: 40px;
    width: 40px;

    ${(props) =>
      !props.stacked &&
      `
      margin-right: 25px;
    `}

    ${(props) =>
      props.stacked &&
      `
      height: 42px;
      width: 42px;
      margin-bottom: 10px;

      @media (min-width: ${BREAKPOINTS.md.min}px) {
        height: 62px;
        width: 62px;
      }
    `}
  }
`;
