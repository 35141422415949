import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MotorcycleIcon from 'icons/motorcycle.svg';
import DoorToDoorIcon from 'icons/delivery-truck.svg';
import FlatTireIcon from 'icons/flat-tire.svg';
import ShoppingCartIcon from 'icons/shopping-cart.svg';
import ToolsIcon from 'icons/tools.svg';
import QuadIcon from 'icons/quad.svg';
import { StackedProps } from 'components/types';
import {
  Wrapper,
  OfferElement,
  ImageWrapper,
  Headline,
  StyledP,
} from './OfferRangeStyled';

type OfferProps = {
  icon: string;
  title: string;
  description: string;
};

type IconsDictProps = {
  [key: string]: string;
};

const iconsDict: IconsDictProps = {
  motorcycle: MotorcycleIcon,
  doorToDoor: DoorToDoorIcon,
  flatTire: FlatTireIcon,
  shoppingCart: ShoppingCartIcon,
  tools: ToolsIcon,
  quad: QuadIcon,
};

const OfferTile: React.FC<StackedProps> = ({ stacked }) => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query OfferTileQuery {
        markdownRemark(fileAbsolutePath: { regex: "/offer/" }) {
          frontmatter {
            offer_offers {
              icon
              title
              description
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper stacked={stacked}>
      {markdownRemark.frontmatter.offer_offers.map((offer: OfferProps) => {
        const Icon = iconsDict[offer.icon];

        return (
          <OfferElement key={offer.title} stacked={stacked}>
            <ImageWrapper stacked={stacked}>
              <Icon />
            </ImageWrapper>
            <Headline bold uppercase>
              {offer.title}
            </Headline>
            {stacked && <StyledP>{offer.description}</StyledP>}
          </OfferElement>
        );
      })}
    </Wrapper>
  );
};

export default OfferTile;
